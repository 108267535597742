import { PaymentProviderType } from './cards.type';
import { OnboardingConfigFlowTypes } from './common.type';
import { LayoutWidgetConfig, WidgetNames } from './config.type';
import { isWorkflowPageStateConfig } from './default-constants/v2-utils';
import { PageHooks } from './ui-system-config.v2.type';
import {
  BaseWidgetProperties,
  ComponentLabel,
  GridPosition,
  RightWidgetConfiguration,
  ValidationRules,
  WORKFLOW_POPUPS,
  WidgetFields,
  ZONETYPEOPTION,
} from './workflow.type';

/*
current possible baas tags:
  modulr - denotes backing BAAS is entirely Modulr based i.e. Confirmation of Payee available etc
  integratedFinance - denotes backing BAAS involes IF as the intermediary before other providers
  if-currencycloud - denotes that IF is using currencyCLoud as it's primarily backing BaaS provider for FX etc

  see logic to parse these in the parseRelevantBaasTags function below
*/
export type RelevantBaasTags = PaymentProviderType | 'if-currencycloud';

export function convertWorkflowPageStateConfigToLayoutWidgetConfig(
  config: WorkflowPageStateConfig | LayoutWidgetConfig,
): LayoutWidgetConfig {
  if (isWorkflowPageStateConfig(config))
    return {
      ...config.widgetConfig,
      attributes: {
        ...config.widgetConfig.widgetConfiguration.attributes,
        isSectionPopup: config.widgetConfig.widgetConfiguration.isSectionPopup,
      },
      position: 0,
      widgetIndex: config.widgetConfig.widgetIndex ?? 0,
      name: config.name,
      gridPosition: {},
    };
  else return config;
}

export type WorkflowWidgetConfigurationAttributes = {
  // Three properties that must be present others are widget specific config
  widgetProperties: BaseWidgetProperties;
  validationRules?: ValidationRules[];
  elementsConfig: WidgetFields[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  css_config: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
  linksList?: LinksListType[];
  selectedListOption?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleTemplate?: any;
  hideCondition?: string;
};
export type LinksListType = {
  textContent: string;
  pageIndex?: number;
  pageUrl?: string;
  zone: string;
};

export type WorkflowWidgetConfiguration = {
  // Used to display name on left hand side
  widgetDisplayName: string;
  // Used to display name on right hand side
  widgetConfigDisplayName: string;
  // ONLY attributes contains data that should be mapped into SystemConfig
  attributes: WorkflowWidgetConfigurationAttributes;
  isRemovable: boolean;
  isVisible?: boolean;
  uniqueId?: string | number;
  nestedWidgets?: {
    [uniqueId: string]: NestedWorkflowWidgetConfiguration;
  };
};

export type NestedWorkflowWidgetConfiguration = {
  name: WidgetNames;
  // Used to display name on right hand side
  widgetConfigDisplayName: string;
  // ONLY attributes contains data that should be mapped into SystemConfig
  attributes: WorkflowWidgetConfigurationAttributes;
  isRemovable?: boolean;
};

export function isWorkflowWidgetConfiguration(
  config: RightWidgetConfiguration | WorkflowWidgetConfiguration,
): config is WorkflowWidgetConfiguration {
  return (<WorkflowWidgetConfiguration>config).widgetDisplayName !== undefined;
}

export type HookAction = 'add' | 'remove';

export type WorkflowWidgetConfig = {
  widgetTypeConfig: 'new';
  uniqueId: string | number;
  name: string;
  widgetConfiguration: WorkflowWidgetConfiguration;
  gridPosition: GridPosition;
  widgetIconPath: string;

  // Used to know what page states to add/remove from Page when this widget is added or removed from page
  additionalPageStatesArray?: WorkflowPageStateConfig[];
  // Used to know what page states to add/remove from Page when this widget is added or removed from page
  alternativeAddWidgets?: WorkflowWidgetConfig[];
  // Used to add/remove extra pages on zone when this widget is added or removed from page
  zoneHook?: string;
  // Used to add/remove/modify extra zones on role when this widget is added or removed from page in a zone
  roleHook?: string;
  // Used to trigger popup when widget is dropped, e.g: custom question, image/carousel widget
  popupType?: WORKFLOW_POPUPS | null;
};

export type WorkflowPageStateWidgetConfig = {
  uniqueId: string | number;
  widgetIndex?: number;
  widgetConfiguration: {
    // Used to display name on right hand side
    widgetConfigDisplayName: string;
    isSectionPopup: boolean;
    showConfig: boolean;
    // ONLY attributes contains data that should be mapped into SystemConfig
    attributes: WorkflowWidgetConfigurationAttributes;
  };
};

export type WorkflowPageStateConfig = {
  pageStateTypeConfig: 'new';
  widgetConfig: WorkflowPageStateWidgetConfig;
  name: ComponentLabel;
  title: string;
};

export type CommonPageConfig = {
  mainComponentPageName: string;
  isRemovable: boolean;
  url: string;
  pageType: 'dynamicGrid' | 'staticGrid';
  gridsterMargin?: {
    left: number;
    right: number;
    top: number;
    unit: string;
  };
  showHeader?: boolean;
  labelsAsPlaceholder?: boolean;
  backgroundColor?: string;
  fontsConfig?: {
    [key: string]: { [key: string]: string };
    h1: { 'font-family': string; 'font-weight': string };
    h2: { 'font-family': string; 'font-weight': string };
    h3: { 'font-family': string; 'font-weight': string };
    bodyFont: { 'font-family': string; 'font-weight': string };
  };
  backgroundGradient?: string;
  backgroundSize?: string;
  backgroundImage?: {
    image: string;
    opacity: string;
  };
  backgroundPosition?: {
    x: string;
    y: string;
  };
  isCustomGridActive?: boolean;
  isBodyScrollingEnabled?: boolean;
  autoRedirectConfig?: {
    isAutoRedirectEnabled?: boolean;
    autoRedirectTimer?: number;
    autoRedirectPersona?: string;
    autoRedirectDestinationZone?: string;
    autoRedirectDestinationPageIndex?: number;
    autoRedirectDestinationPageUrl?: string;
  };
  pageHooks?: PageHooks[];
};

export type WorkflowPageConfig = {
  pageConfiguration: CommonPageConfig;
  name: string; /// Maybe can just be a unique id in future?
  title: string;
  pagesStates: WorkflowPageStateConfig[];
  widgets: WorkflowWidgetConfig[];
};

export type WorkflowZoneConfig = {
  zoneTypeConfig: 'new';
  zoneConfiguration: ZoneConfiguration;
  pages: WorkflowPageConfig[];
};

export type WorkflowZoneDevicesConfig = {
  zoneDevicesTypeConfig: 'new';
  mobile: WorkflowZoneConfig | null;
  tablet: WorkflowZoneConfig | null;
  desktop: WorkflowZoneConfig | null;
};

export type ZoneConfiguration = {
  zonePageType: 'tabular' | 'singlePage';
  showNextButton?: boolean;
  zoneType: ZONETYPEOPTION;
  showNextBtn?: boolean;
  showBackBtn?: boolean;
};
export enum DummyRole {
  EMPTY_ROLE = 'EmptyRole',
}

export enum PaymentFlowTypes {
  UK_FASTER_PAYMENTS = 'uk-faster-payments',
  SEPA = 'sepa',
  SWIFT = 'swift',
  CURRENCY_ACCOUNT = 'currency-accounts',
  ALL_PAYMENTS = 'all-payments',
  BULK_PAYMENTS = 'bulk-payments',
  WITH_PERMISSIONS = 'with_permissions',
  WITH_OUT_PERMISSIONS = 'with_out_permissions',
  APPROVER = 'approver',
  REQUESTOR = 'requestor',
  INITIATOR = 'initiator',
}

export enum OrganisationProfileType {
  ORGANISATION_PROFILE = 'organisation-profile',
  FAQ = 'FAQ',
}

export const ProfilePagesTags = {
  DEFAULT_PAGE: 'landing_page',
  USER_PROFILE: 'user_profile',
  SETTINGS: 'settings',
  NOTIFICATIONS_CENTER: 'notifications_center',
  PRIVACY_POLICY: 'privacy_policy',
  FAQS: 'faqs',
  PASSWORD: 'password_manager',
  FEEDBACK_SUPPORT: 'feedback_&_support',
  CURRENCYCLOUD_REGULATIONS: 'regulations_currencycloud',
  BANK_STATEMENTS: 'bank_statements',
} as const;

export const UserManagementPagesTags = {
  USER_DETAILS: 'user_details',
  ADD_USER_AND_PERSONA: 'add_user_and_assign_persona',
} as const;

export enum BeneficiaryZonePagesTags {
  VIEW_BENEFICIARY = 'view_beneficiaries',
  EDIT_BENEFICIARY = 'edit_beneficiaries',
  DELETE_BENEFICIARY = 'delete_beneficiaries',
}

export enum CardManagementZonePageTags {
  PREPAID_VIRTUAL_CARD_TOP_UP = 'prepaid_virtual_card_top_up',
  PREPAID_PHYSICAL_CARD_TOP_UP = 'prepaid_physical_card_top_up',
  DEBIT_PHYSICAL_CARD_CURRENT = 'debit_physical_card_current',
  PREPAID_PHYSICAL_CARD_CURRENT = 'prepaid_physical_card_current',
}
export interface ProjectSettingsRequest {
  projectId: string;
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  smsAuthenticationEnabled: boolean;
  checkPayeeEnabled: boolean;
  feeManagementEnabled: boolean;
  retryFailedFeeEnabled: boolean;
  feeReversalEnabled: boolean;
}

export enum RevenirZonePagesTags {
  revenir = 'revenir',
}
