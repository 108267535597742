import { formatDate } from '@angular/common';
import { TripPayload } from '@finxone-platform/shared/sys-config-types';

export function createRevenirTripPayload(trip: TripFormPayload): TripPayload {
  return {
    start_date: formatDate(new Date(trip.startDate), 'dd/MM/yyyy', 'en'),
    end_date: formatDate(new Date(trip.endDate), 'dd/MM/yyyy', 'en'),
    mode_of_travel: trip.modeOfTravel,
    departure_terminal: trip.departureInput,
    country: getFirstThreeLetters(trip.entering_country),
    trip_name:
      trip.entering_country.toUpperCase() +
      ' ' +
      formatDate(new Date(trip.startDate), 'd', 'en') +
      ' - ' +
      formatDate(new Date(trip.endDate), 'dd/MM/yy', 'en'),
    country_entry: getFirstThreeLetters(trip.entering_country),
    country_exit: getFirstThreeLetters(trip.leaving_country),
    country_destination: getFirstThreeLetters(trip.leaving_country),
    document_id: trip.document_id,
    // approxTime: trip.approxTime,
  } as TripPayload;
}
const countriesWithCode = [
  { title: 'france', code: 'FRA' },
  { title: 'italy', code: 'ITA' },
  { title: 'portugal', code: 'PRT' },
  { title: 'netherlands', code: 'NLD' },
  { title: 'belgium', code: 'BEL' },
];
function getFirstThreeLetters(str: string): string {
  const countryCode = countriesWithCode.find((x) => x.title == str)?.code;
  if (countryCode) {
    return countryCode;
  } else {
    return str.toUpperCase();
  }
}

type TripFormPayload = {
  startDate: string;
  endDate: string;
  entering_country: string;
  leaving_country: string;
  approxTime: string;
  modeOfTravel: string;
  departureInput: string;
  document_id: string;
};
