import { Locale } from '../../../locale.enum';
import { FeeManagementErrorCodes } from '../error-codes/fee-mangement.type';
import { FeeManagementSuccessCodes } from '../success-codes/fee-mangement.type';

export const feeManagementTranslations = {
  [Locale.en_GB]: {
    FEE_CHARGE_ALREADY_APPLIED_TITLE: 'Fee charge already applied',
    FEE_CHARGE_ALREADY_APPLIED_DESCRIPTION: 'Fee charge already applied or reverted',
    FEE_CHARGE_ITEM_NOT_FOUND_TITLE: 'Charge not found',
    FEE_CHARGE_ITEM_NOT_FOUND_DESCRIPTION: 'Fee charge item not found',
    RETRY_SUCCESSFUL_TITLE: 'Fee Charged Successfully.',
    RETRY_SUCCESSFUL_DESCRIPTION:
      "The fee has been successfully charged to users's account. The status has been updated to 'Charged'",
    REVERT_SUCCESSFUL_TITLE: 'Fee Successfully Reverted',
    REVERT_SUCCESSFUL_DESCRIPTION:
      "Fee has been successfully reverted, and a credit to transaction has been applied to client's account",
    FEE_CHARGE_APPLY_PROCESS_TITLE: 'Fee Charged Failed.',
    FEE_CHARGE_APPLY_PROCESS_DESCRIPTION:
      "The fee could not be charged into users's account. Please review the reason and try again",
    FEE_CHARGE_REVERT_PROCESS_TITLE: 'Reversal Failed',
    FEE_CHARGE_REVERT_PROCESS_DESCRIPTION:
      'The fee reversal failed. Please try again or contact support if issue persist',
    FEE_CHARGE_APPLY_PROCESS_CONFIG_TITLE: 'Fee charge configuration issue',
    FEE_CHARGE_APPLY_PROCESS_CONFIG_Description: 'Fee charge setup configuration issue occured.',
  },
  [Locale.fr_FR]: {
    FEE_CHARGE_ALREADY_APPLIED_TITLE: 'Frais déjà appliqués',
    FEE_CHARGE_ALREADY_APPLIED_DESCRIPTION: 'Frais déjà appliqués',
    FEE_CHARGE_ITEM_NOT_FOUND_TITLE: 'Frais non trouvés',
    FEE_CHARGE_ITEM_NOT_FOUND_DESCRIPTION: 'Élément de frais non trouvé',
    RETRY_SUCCESSFUL_TITLE: 'Réessai de frais réussi',
    RETRY_SUCCESSFUL_DESCRIPTION: 'Le réessai de frais a été réussi.',
  },
  // Add more locales as needed
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateFeeManagementAlerts = (translations: any) => ({
  [FeeManagementErrorCodes.FEE_CHARGE_ALREADY_APPLIED]: {
    name: 'Fee charge already applied',
    type: 'Error',
    title: translations.FEE_CHARGE_ALREADY_APPLIED_TITLE,
    description: translations.FEE_CHARGE_ALREADY_APPLIED_DESCRIPTION,
  },
  [FeeManagementSuccessCodes.FEE_RETRY_SUCCESSFUL]: {
    name: 'Fee charge retry success',
    type: 'Success',
    title: translations.RETRY_SUCCESSFUL_TITLE,
    description: translations.RETRY_SUCCESSFUL_DESCRIPTION,
  },
  [FeeManagementErrorCodes.FEE_CHARGE_ITEM_NOT_FOUND]: {
    name: 'Fee charge item not found',
    type: 'Error',
    title: translations.FEE_CHARGE_ITEM_NOT_FOUND_TITLE,
    description: translations.FEE_CHARGE_ITEM_NOT_FOUND_DESCRIPTION,
  },
  [FeeManagementErrorCodes.FEE_CHARGE_APPLY_PROCESS]: {
    name: 'Fee charge apply processing error',
    type: 'Error',
    title: translations.FEE_CHARGE_APPLY_PROCESS_TITLE,
    description: translations.FEE_CHARGE_APPLY_PROCESS_DESCRIPTION,
  },
  [FeeManagementErrorCodes.FEE_CHARGE_APPLY_PROCESS_CONFIG]: {
    name: 'Fee charge config issue',
    type: 'Error',
    title: translations.FEE_CHARGE_APPLY_PROCESS_CONFIG_TITLE,
    description: translations.FEE_CHARGE_APPLY_PROCESS_CONFIG_DESCRIPTION,
  },
  [FeeManagementSuccessCodes.FEE_REVERT_SUCCESSFUL]: {
    name: 'Fee charge reverted',
    type: 'Success',
    title: translations.REVERT_SUCCESSFUL_TITLE,
    description: translations.REVERT_SUCCESSFUL_DESCRIPTION,
  },
  [FeeManagementErrorCodes.FEE_CHARGE_REVERT_PROCESS]: {
    name: 'Fee charge revert processing error',
    type: 'Error',
    title: translations.FEE_CHARGE_REVERT_PROCESS_TITLE,
    description: translations.FEE_CHARGE_REVERT_PROCESS_DESCRIPTION,
  },
});
