import { RevenirTripType } from '@finxone-platform/shared/sys-config-types';

export class GetRevenirTransactionList {
  static readonly type = '[RevenirState] GetRevenirTransactionList';
  constructor(public tripId: string) {}
}
export class GetRevenirTripList {
  static readonly type = '[RevenirState] GetRevenirTripList';
}
export class SetRevenirTrip {
  static readonly type = '[RevenirState] SetRevenirTrip';
  constructor(public selectedTripDetails: RevenirTripType) {}
}

export class GetRevenirTransactionDetails {
  static readonly type = '[RevenirState] GetRevenirTransactionDetails';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionItems {
  static readonly type = '[RevenirState] GetRevenirTransactionItems';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionReceipt {
  static readonly type = '[RevenirState] GetRevenirTransactionReceipt';
  constructor(public transactionId: string) {}
}

export class GetRevenirArchivedTrips {
  static readonly type = '[RevenirState] GetRevenirArchivedTrips';
}

export class SetArchivedRevenirTrip {
  static readonly type = '[RevenirState] SetArchivedRevenirTrip';
  constructor(public archivedTripDetails: RevenirTripType) {}
}

export class ClearArchivedRevenirTrip {
  static readonly type = '[RevenirState] Clear Archived Trip';
}

export class MakeTripAsArchiveTrip {
  static readonly type = '[RevenirState] MakeTripAsArchiveTrip';
  constructor(public trip: RevenirTripType) {}
}

export class GetRevenirBarcodeImage {
  static readonly type = '[RevenirState] GetRevenirBarcodeImage';
  constructor(public tripId: string) {}
}
export class GetPortList {
  static readonly type = '[RevenirState] GetPortList';
  constructor(public countryCode: string, public type: string) {}
}

export class UploadBoardingPass {
  static readonly type = '[RevenirState] UploadBoardingPass';
  constructor(public payload: FormData) {}
}
export class GetBoardingPass {
  static readonly type = '[RevenirState] GetBoardingPass';
  constructor(public tripId: string) {}
}
